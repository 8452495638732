.login-form{
  padding-left: 5%;
  padding-right: 5%;
  .form-row{
    padding-bottom: 15px;
  }
  .fb-login-btn{
    color: white;
    background-color: #0071EB;
    border-color: #0071EB;
    width: 100%;
    line-height: 1.5;
    border-radius: .3rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 17px;
    padding: .5rem 1rem;
    margin-top: 10px;
    margin-bottom: 10px;
    svg{
      margin-right: 5px;
      margin-bottom: 4px;
    }
  }
  .terms{
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 0.55;
    font-weight: 500;
    a{
      font-family: 'Open Sans', sans-serif;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      color: black;
      text-decoration: underline;
    }
  }
}