.conversation{
  margin:20px;
  .message-form{
    margin-top: 20px;
    background-color: white;
    border-radius: 11px;
    padding-top: 5px;
    textarea{
      resize: none;
      border: none;
    }
    .send-button{
      width: 100%;
      padding-right: 15px;
      padding-bottom: 10px;
      button{
        float: right;
        font-size: 14px;
        border-radius: 8px;
      }
    }
  }

  .message{
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;

    justify-content: flex-start;
    &.mine{
      justify-content: flex-end;

      .content{
        color: white;
        margin-left: 25%;
        background: linear-gradient(to bottom, #3781CA 0%, #3781CA 100%);
        background-attachment: fixed;
        &.last{

        }
      }
    }
    .content{
      background-color: #eee;
      border-radius: 20px;
      padding: 8px 15px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}