.leaderboards{
  height: 100%;
  overflow: auto;
  .nav-tabs{
    border-color: transparent;
    background-color: #1F1E1E;
    opacity: .87;
    padding-bottom: 5px;
    padding-top: 5px;
    border-top: rgba(216, 216, 216, .2) 1px solid;
    .nav-link{
      width: 50%;
      color: rgba(255,255,255,0.35);
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      font-size: 22px;
      text-transform: lowercase;
      &.border-right{
        border-right: rgba(216, 216, 216, .2) 1px solid!important;
      }
      &:hover{
        border-color: transparent;
      }
      &.active{
        color: white;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}
