.submit-track-form{
  padding-left: 5%;
  padding-right: 5%;

  .text-link{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .player-wrapper{
    padding-top: 0;
    margin-bottom: 20px;
    &.valid-link{
      padding-top: 56.25%;;
    }
  }
}