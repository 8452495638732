.profile-view{
  height: 100%;
  overflow: auto;
    .badges{
      margin: 20px;
      .badge-wrapper {
        padding:10px;
        display: inline-block;
        .user-badge {
          display: inline-block;
          width: 200px;
          height: 100%;
          font-size: 14px;
          color: white;
          font-family: 'Open Sans', sans-serif;
          font-weight: bold;
          text-align: center;
          padding: 10px;
          background-color: #1F1E1E;
          border-radius: 15px;
          .status{
            font-size: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .name{
            padding-bottom: 10px;
          }
          .progress-text{
            color: white;
            opacity: .6;
            text-align: center;
            position: relative;
            height: 0;
            font-style: italic;
            text-transform: lowercase;
          }
          .progress{
            background-color: #DB6E57;
            .progress-bar{
              background-color: #D8D8D8;
              opacity: .15;
            }
          }
        }
      }
    }
}