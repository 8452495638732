.submission-rater {
  overflow-x: hidden;
  padding-left: 25px;
  padding-right: 25px;
  overflow: auto;
  height: 100%;
}

.rate-icon{
  width: 35px;
}

.submission{
  margin-top: 30px;
  background-color: #1B3265;
  border-radius: 10px;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 1);

  &.last{
    margin-bottom: calc(100% / 4);
  }

  .flag.text-link{
    a {
      color: white;
    }
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    font-style: italic;
  }
  .comment-form{
    width: 100%;
    .form-group{
      margin: 0;
    }
    .comment-send{
      float: right;
      margin-right: 10px;
      margin-bottom: 10px;
      text-transform: uppercase;
      color: white;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      &.btn-primary{
        background-color: #DB6E57;
        border-color: #DB6E57;
      }
    }
    textarea {
      background-color: transparent;
      border: none;
      width: 100%;
      resize: none;
      font-family: 'Open Sans', sans-serif;
      color: white;
      opacity: .47;
      font-weight: 300;
    }
  }
  .artist{
    text-align: center;
    font-size: 14px;
    color: white;
    font-family: 'Open Sans', sans-serif;
    opacity: .6;
  }
  .title{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: white;
    padding-top: 10px;
    font-family: 'Open Sans', sans-serif;
  }
  .time-left{
    color: white;
    opacity: .6;
    text-align: center;
    position: relative;
    top: 20px;
    height: 0;
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    text-transform: lowercase;
  }
  .tap-to-start{
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    text-transform: lowercase;
    color: white;
    opacity: .6;
  }
  .progress{
    height: 65px;
    background-color: #DB6E57;
    .progress-bar{
      background-color: #D8D8D8;
      opacity: .15;
    }
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .player-container{

  }
  .ratebar{
    min-height: 65px;
    display: flex;
    background-color: #DB6E57;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &.center{
      justify-content: center;
      align-items: center;
    }
    &.action{
      cursor: pointer;
    }
    &.comment{
      flex-direction: column;
    }
    .rating{
      position: relative;
      top: -75px;
      height: 0;
      &.comment{
        top: -50px;
      }
    }
    .comment-content{
      text-align: center;
      overflow-x: scroll;
      white-space: nowrap;
    }
    .tap-to-comment,.comment-content{
      .text{
        font-family: 'Open Sans', sans-serif;
        font-style: italic;
        text-transform: lowercase;
        color: white;
        opacity: 0.6;
        padding-left: 10px;
      }
    }
    .rate-section {
      width: 100%;
      .divider {
        height: 0;
        width: 0;
        float: right;

        .content {
          background-color: white;
          opacity: .4;
          margin-top: 20px;
          width: 2px;
          height: 30px;
        }
      }
      .rate-btn {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        flex-flow: column;
        padding: 5px;

        .rate-text{
          text-align: center;
          font-weight: bold;
          font-size: 11px;
          color: white;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }
    .btn-group{
      height: 65px;
      .btn-primary{
        background-color: #DB6E57;
        border-color: #DB6E57;
      }
    }
  }
}