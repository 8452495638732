html {
  scroll-behavior: smooth;
}
html{
  min-height: 100%;
  height: 100%;
}
body,#root,.landing-page{
  height: 100%;
}
#root{
  background-color: #E4F2EE;
  max-width: 600px;
  max-height: 1024px;
  width: 100%;
}
body{
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing-page-content{
  height: calc(100% - 60px - 73px);
  overflow: hidden;
  background-color: #E4F2EE;
  .content-wrapper{
    height: 100%;
  }
  &.container {
    padding: 0;
    margin: 0;
    max-width: 100%;
  }
}

.right-align{
  justify-content: flex-end;
}

.middle-align{
  justify-content: center;
}

.half-width{
  width: 50%;
}

.flex-container{
  display: flex;
}

.full-width{
  width: 100%;
}

.player-container.rated.cont-scroll{
  .player-wrapper{
    padding-top: 25%;
  }
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.kep-login-facebook.metro{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 50px;
}

.modal-close-btn{
  display: block;
  float: right;
  cursor: pointer;
  padding-top: 10px;
  padding-right: 10px;
}

.modal-title{
  padding-top: 40px;
}
.modal-body{
  padding: 0!important;
}
.modal-wn-title{
  padding-left: 20%;
  padding-right: 20%;
  .main-title{
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    text-align: center;
    font-size: 22px;
    color: #1B3265;
  }
  .sub-title{
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 60px;
    opacity: .45;
  }

}

.payment-title{
  padding-bottom: 30px;
  .sub-title{
    padding-top: 0;
    padding-bottom: 0px;
  }
}

.forgot-end{
  font-family: "Open Sans",sans-serif;
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 60px;
}

.wn-form {
  input {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 17px;
    color: black;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid rgb(216, 216, 216);
    border-radius: 0;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #DB6E57;
    }
  }

  .form-check{
    input[type=checkbox] + label {
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 15px;
      color: black;
    }
  }

  .btn-primary {
    background-color: #DB6E57;
    border-color: #DB6E57;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 17px;

    &:hover{
      background-color: #DB6E57!important;
      border-color: #DB6E57!important;
    }

    &:disabled {
      background-color: #DB6E57;
      border-color: #DB6E57;
      opacity: .40;
    }
  }

  .forgot-link{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .text-link {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;

    a {
      color: black;
      text-decoration: underline;
    }
  }
}

.wn-list{
  .item{
    display: flex;
    align-items: center;
    border-bottom: #D8D8D8 2px solid;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #504E54;
    padding-right: 7%;
    padding-left: 7%;

    &.action{
      cursor: pointer;
      background-color: #F4F1E5;
      &:hover{
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.curators{
    .item{
      .position{
        width: 10%;
        text-align: left;
      }
      .name{
        width: 60%;
      }
      .points{
        width: 30%;
        text-align: right;
        font-weight: 300;
        color: black;
        .small{
          font-size: 12px;
        }
      }
    }
  }
  &.tracks{
    .item{
      cursor: pointer;
      .position{
        width: 10%;
        text-align: left;
      }
      .name{
        width: 60%;
        .artist-name{
          font-size: 14px;
          font-weight: 300;
        }
      }
      .points{
        width: 30%;
        text-align: right;
        font-weight: 300;
        color: black;
        .small{
          font-size: 12px;
        }
      }
    }
  }

  &.user-cards{
    .item{
      flex-flow: column;
      padding: 20px;
      border: #D8D8D8 2px solid;
      margin-bottom: 20px;
    }
  }
  &.user-submissions{
    .item{
      padding-right: 2%;
      padding-left: 2%;
      .create-date{
        width: 27%;
        text-align: left;
        margin-left: 5px;
        margin-right: 5px;
      }
      .title{
        width: 25%;
        margin-left: 5px;
        margin-right: 5px;
        word-break: break-word;
      }
      .status{
        width: 25%;
        text-align: center;
        font-weight: 300;
        color: black;
        margin-left: 5px;
        margin-right: 5px;
      }
      .in-contests{
        width: 25%;
        text-align: left;
        margin-left: 5px;
        margin-right: 5px;
        .optin-edit{
          cursor: pointer;
          position: relative;
          font-size: 18px;
          top: -2px;
        }
        .optin-text-edit{
          cursor: pointer;
        }
      }
    }
  }
  &.user-rated{
    .item{
      .create-date{
        width: 40%;
        text-align: left;
      }
      .title{
        width: 45%;
      }
      .status{
        width: 25%;
        text-align: right;
        font-weight: 300;
        color: black;
      }
    }
  }
  &.user-profile{
    .item{
      .sb-avatar{
        margin-right: 20px;
      }
      .stacked-title{
        width: 100%;
      }
    }
  }
  &.conversations-container, &.conversation-preview-container, &.submissions-result{
    .item{
      .sb-avatar{
        margin-right: 20px;
      }
      .stacked-title{
        width: 60%;
        .track{
          font-size: 14px;
        }
      }
      .rating-container{
        width: 40%;
        text-align: right;
        font-weight: 300;
        color: black;
      }
      .comment-text{
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.user-submissions,.rated-submissions{
  overflow: auto;
  height: 100%;
}

.submission-modal{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.edit-user-modal{
  padding: 20px;
}

.nothing-more{
  padding: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 17px;
  color: #504E54;
}

.position-icon{
  width: 35px;
}

.pagination-container {
  justify-content: center;
  display: flex;
  width: 100%;
  overflow: scroll;
  .pagination {
    max-width: 100%;
    margin: 10px 0;

    .page-item {
      width: 100%;
    }
  }
}

.centered-button-row{
  margin: 10px;
  display: flex;
  justify-content: center;
}

.custom-input.invalid{
  input {
    border-color: #dc3545;
  }
}