.user-cards{
  margin:20px;

  .item {
    background-color: white;
    align-items: stretch;
    .delete-button {
      display: block;
      margin-top: 5px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    border-radius: 10px;
    .action{
      cursor: pointer;
    }
    .expiration{

    }
    .last4{

    }
  }
}