.admin-flagIssueDetails{
  .problem{
    white-space: nowrap;
  }
  .paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }

  .paginate li {
    display: inline-block;
  }

  .search-details{
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}