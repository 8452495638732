.submission-results{
  .card-title{
    display: flex;
    align-items: center;
  }
  .stacked-title{
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    .name{
      font-size: 14px;
    }
    .date{
      font-size: 12px;
    }
  }
  .rating-container{
  }

  .conversation{
    .message-form{
      background-color: #F4F1E5;
      textarea{
        background-color: #F4F1E5;
      }
    }
  }
}