.checkout{
  .checkout-content{
    padding-left: 20px;
    padding-right: 20px;
  }
  &.container {
    padding: 12px 0 0;
  }
  .product{
    width: 100%;
    background-color: black;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 17px;
    padding-top: 12px;
    padding-bottom: 12px;
    .name {
      font-weight: bold;
      color: #FCBA27;
    }
    .price{
      font-weight: 300;
      color: white;
    }
  }
  .button-aligner{
    padding-left: 20px;
    padding-right: 20px;
  }
  .text-link{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .wn-form{
    margin: 20px;
  }
}