.admin-contests{
  .button-row{
    margin: 10px;
    display: flex;
    justify-content: center;
  }
  .notes{
    .center{
      text-align: center;
    }
    margin: 10px;
    background-color: #1F1E1E;
    border-radius: 10px;
    font-weight: bold;
    font-size: 12px;
    padding: 5px;
    color: white;
    font-family: 'Open Sans', sans-serif;
  }
}

.add-edit-form{
  margin: 20px;
}