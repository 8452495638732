.cards-page{
  height: 100%;
  overflow: auto;
  .wn-form{
    padding: 10px 20px;
    button{
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  .checkout-form{
    background-color: white;
    border-radius: 15px;
  }

  .item {
    .last4,.expiration{
      width: 100%;
    }
  }
}