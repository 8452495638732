.banner{
  .carousel-root{
    background-color: black;
    .slider {
      margin-bottom: 30px;
    }
  }
  margin-top: 10px;
  .dismiss{
    position: relative;
    z-index: 999;
    button{
      position: absolute;
      right: 30px;
      top: 12px;
      opacity: .7;

      width: 40px;
      height: 40px;
      padding: 7px 10px;
      border-radius: 25px;
      font-size: 10px;
      text-align: center;
    }
  }
  .carousel{
    .control-dots{
      padding: 0;
    }
  }
}