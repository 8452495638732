.messages-view{
  height: 100%;
  overflow: auto;
  .conversations-container{
    padding-top: 20px;
  }
  .conversations-container,.conversation-preview-container{
    .card {
      margin-top: 10px;
      margin-bottom: 10px;
      .card-body {
        padding: 15px
      }

      .card-title {
        display: flex;
        align-items: center;
        margin: 0;
      }

      .stacked-title {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;

        .name {
          font-size: 14px;
        }

        .track {
          font-size: 16px;
          font-weight: bolder;
        }
      }

      .rating-container {
      }
    }
  }

}