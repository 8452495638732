.last-checkout-link{
    .last-checkout-item-link{
        border: 1px solid #333;
        padding: 5px;
        margin: 10px;
        border-radius: 5px;
    }
}
.last-checkout-button{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 20px;
    .last-checkout-button__continue{
        width: 50% !important;
        min-width: 200px !important;
        background-color: #DB6E57;
        border-color: #DB6E57;
        &:hover{
            background-color:#DB6E57;
            border-color: #DB6E57;
            color: #fff;
        }
    }
}

.unpaid-track{
    background-color: white;
    margin: 5%;
    border-radius: 3%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
}
