.app-navbar{
  .nav-footer{
    display: flex;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 10px;
    border-top: rgba(216, 216, 216, .32) 2px solid;
    padding-left: 10px;
    padding-right: 10px;
    .text{
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 14px;
      color: white;
      opacity: .40;
      .aligner{
        width: 60%;
      }
      a{
        color: white;
        text-decoration: none;
        padding-right: 10px;
      }
    }
    .logo{
      img{
        width: 50px;
        position: relative;
      }
    }
  }
  &.bg-dark {
    background-color: #1F1E1E !important;
  }
  &.navbar-dark{
    .navbar-toggler {
      border-color: transparent;
      font-size: 1rem;
      &.collapsed{
        .navbar-toggler-icon {
          font-size: 25px;
          background-image: url("../../images/mobile-close.png");
        }
      }
    }
    .navbar-toggler-icon{
      font-size: 25px;
      background-image: url("../../images/mobile-menu.svg");
    }
  }
  .navbar-collapse{
    border-top: rgba(216, 216, 216, .32) 2px solid;
    margin-top: 10px;
  }
  .nav-item{
    .dropdown-menu{
      background-color: #1F1E1E;
      border: none;
      .dropdown-item{
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        font-size: 20px;
        color: white;
        &.active{
          background-color: #353434;
        }
        &:hover{
          background-color: #525151;
        }
      }
    }
  }
  .navbar-brand{
    width: calc(100% - 80px);
    .nav-title{
      width: calc(100% - 64px);
      display: inline-block;
      text-align: center;
      text-transform: uppercase;
      font-family: 'Open Sans', sans-serif;
      font-weight: 900;
      font-size: 22px;
      color: #FA937D;
    }
  }
  .nav-link{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 20px;
  }
  .wn-logo{
    width: 75px;
    top: -4px;
    position: relative;
  }
}