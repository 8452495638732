.optin-form{
  padding: 33px;
  .form-title{
    padding-bottom: 25px;
    margin: 0;
  }
  &.wn-form .form-check input[type=checkbox] + label{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    color: #504E54;
  }

  .form-radio-group{
    .form-check{
      margin-bottom: 15px;
      &.no-margin{
        margin: 0;
      }
    }
  }

  .form-group{
    margin-bottom: 25px;
  }
  .form-label{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: #504E54;
  }

  .form-radio-group{
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  .form-check{
    label{
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 15px;
      color: #504E54;
      .light{
        font-weight: 400;
      }
    }
  }
}