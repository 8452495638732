.checkout-form.container {
  padding: 10px 30px 10px 30px;

  .billing{
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 18px;
    opacity: .51;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .form-group{
    margin: 0;
  }
  .field-label{
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size: 12px;
    opacity: .51;
  }
  .form-check{
    padding-top: 7px;
    label{
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      font-size: 12px;
    }
    .form-check-input{
      margin-top: 7px;
    }
  }
  form{
    padding-top: 15px;
    padding-bottom: 50px;
  }

  .continue-container{
    display: flex;
    flex-flow: column;
    button{
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
  .cancel{
    margin-top: 30px;
  }

  input:not([type='checkbox']),.StripeElement {
    display: block;
    margin: 0 0 5px 0;
    max-width: 500px;
    padding: 5px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 0, rgba(0, 0, 0, 0.0196078) 0 1px 0;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  input::placeholder {
    color: #aab7c4;
  }

  input:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.14902) 0 3px 0, rgba(0, 0, 0, 0.0196078) 0 2px 0;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
}