.flagged-submissions{
  .type-selector{
    width: 100%;
  }
  .table-responsive{
    margin-top: 10px;
  }
}

.problem-submission{
  margin: 20px;
  .submission{
    padding: 10px;
    margin: 20px;
  }
  .wn-form{
    margin: 20px;
  }
}