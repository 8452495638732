.landing-page{
  .submit-container {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 10px 50px;

    .submit-track-button {
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      margin-top: 5px;
      &.btn-primary {
        color: #faf9f4;
        background-color: #DB6E57;
        border-color: #DB6E57;
        &:hover{
          background-color: #DB6E57!important;
          border-color: #DB6E57!important;
        }
      }

      .music-icon {
        margin-right: 6px;
      }
    }
  }

  .app-navbar.navbar-expand-md,.app-navbar.navbar-expand{
    flex-flow: row wrap!important;
  }

  .contest-message{
    text-align: center;
    margin: 10px 5px;
    background-color: #1F1E1E;
    border-radius: 10px;
    font-weight: bold;
    font-size: 12px;
    padding: 5px;
    color: white;
    font-family: 'Open Sans', sans-serif;
    &.invalid{
      background-color: #e74c3c ;
    }
    .text-link a{
      color:white;
      font-size: 12px;
      font-weight: bold;
    }
  }
}